<template>
  <div>
    <div class="project flexible" v-if="userRole === '0' || userRole === '1'">
      <div class="main-content">
        <div class="page-title clearfix">
          <div class="float-left">
            <span>{{ $t("nav.project_manage") }}</span>
            <span class="total-num">{{totalCount}}</span>
          </div>
        </div>
        <div class="project-content">
          <!-- <div class="search-wrapper">
            <form class="form-inline">
              <button type="submit" class="btn btn-dark btn-sm">{{ $t("search") }}</button>
              <div class="form-group">
                <input
                  type="search"
                  name="search"
                  class="form-control"
                  placeholder
                />
              </div>
            </form>
          </div> -->
          <div class="tabel-content">
            <el-table
              :data="projectList"
              :empty-text="$t('no_data')"
              style="width: 100%"
              header-row-class-name="thead-dark"
            >
              <el-table-column :label="$t('project.name')">
                <template v-slot="scope">
                  <router-link
                    :to="{ name: 'projectData', query: { id: scope.row.id } }"
                    >{{ scope.row.name }}</router-link
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="operator"
                :label="$t('project.owner') + '(' + $t('sign.username') +')'"
              >
                <template v-slot="scope">
                  <span>
                    {{ scope.row.operator }} ({{ scope.row.project_manager.username }})
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="project_manager.department_name"
                :label="$t('project.department')"
              ></el-table-column>
              <el-table-column
                prop="project_data"
                :label="$t('project.p_num')"
              ></el-table-column>
              <el-table-column
                prop="project_users"
                :label="$t('project.p_mem')"
              ></el-table-column>
              <el-table-column
                prop="create_time"
                :label="$t('project.c_time')"
              ></el-table-column>
              <el-table-column
                prop="update_time"
                :label="$t('project.u_time')"
              ></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="clearfix mt-3 mb-3">
          <!-- <div class="float-left">
          <pagination 
            :pageSize="pageSize"
            :layout="layout"
            :currentPage="currentPage"
            :total="50"
            @goToPage="handleCurrentChange"
          />
          </div>-->
        </div>
      </div>
      <div
        class="empty-wrapper"
        :class="showSearchDrawer ? 'active' : ''"
      ></div>
      <div class="search-drawer" :class="showSearchDrawer ? 'active' : ''">
        <p class="top-line clearfix">
          <span>{{ $t("a_search") }}</span>
          <span class="float-right close-search" @click="hideSearchWrapper">关闭</span>
        </p>
      </div>
    </div>

    <div class="project flexible" v-if="userRole !== '0' && userRole !== '1'">
      <div class="main-content">
        <div class="page-title clearfix">
          <div class="float-left">
            <span>{{ $t("nav.project_manage") }}</span>
            <span class="total-num">{{totalCount}}</span>
          </div>
          <div class="float-right">
            <button
              type="button"
              class="btn btn-danger btn-sm"
              @click.stop="showProjectModel('create')"
            >
              {{ $t("project.c_pro") }}
            </button>
          </div>
        </div>
        <div class="project-content">
          <div class="clearfix">
            <div class="float-right search-wrapper">
              <form class="form-inline">
                <!-- <span class="advanced-btn ml10" @click="showSearchWrapper">{{$t("a_search")}}</span> -->
                <button type="submit" class="btn btn-dark btn-sm">
                  {{ $t("search") }}
                </button>
                <div class="form-group">
                  <input
                    type="search"
                    name="search"
                    class="form-control"
                    placeholder
                  />
                </div>
              </form>
            </div>
          </div>
          <div class="row">
            <div
              class="project-item rounded animated fadeInLeft"
              v-for="item in projectList"
              :key="item.id"
            >
              <div class="card mb-4 shadow">
                <div class="card-header bg-transparent">
                  <router-link :to="{ name: 'projectData', query: { id: item.id } }">
                    <strong>{{ item.name }}</strong>
                  </router-link>
                  <span class="float-right" v-if="!item.inviter" @click.stop="deleteProject(item.id)">
                    <i class="el-icon-close"></i>
                  </span>
                  <span v-if="item.inviter" class="float-right text-success">
                    {{$t("project.s_pro")}}
                  </span>
                </div>
                <div class="card-body">
                  <div class="card-text group-list">
                    <span>{{$t('project.group')}}:</span>
                    <span
                      class="badge badge-light"
                      v-for="group in item.group"
                      :key="group.name"
                      >{{ group.name }}</span>
                  </div>
                  <div class="card-text">
                    <div class="row">
                      <div class="col-4 data-owner">
                        <span>{{$t('project.owner')}}{{$t('colon')}}</span>
                        <span>{{item.operator}}</span>
                      </div>
                      <div class="col-6">
                        <span>{{$t('project.manager')}}{{$t('colon')}}</span>
                        <span>{{item.project_manager.realname}}</span>
                      </div>
                    </div>
                    <div class="row" style="margin-top: 5px;">
                      <div class="col-4">
                        <span>{{$t('project.p_mem')}}{{$t('colon')}}</span>
                        <span>{{item.project_users}}</span>
                      </div>
                      <div class="col-4">
                        <span>{{$t('project.p_num')}}{{$t('colon')}}</span>
                        <span>{{item.project_data}}</span>
                      </div>
                      <div class="col-4">
                        <span>{{$t('project.series_num')}}{{$t('colon')}}</span>
                        <span>{{item.project_series}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="card-text project-desc">
                    <span>{{$t('project.p_des')}}{{$t('colon')}}</span>
                    <span>{{item.description}}</span>
                  </div>
                </div>
                <div class="card-footer bg-transparent">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <router-link
                        :to="{ name: 'projectData', query: { id: item.id } }"
                        class="btn btn-sm btn-outline-secondary"
                        >{{ $t("view") }}
                      </router-link>
                      <button
                        v-if="!item.inviter"
                        type="button"
                        class="btn btn-sm btn-outline-secondary"
                        @click.stop="showProjectModel('edit', item)"
                      >{{ $t("edit") }}
                      </button>
                    </div>
                    <div>
                      <div>
                        <span>{{$t('project.c_time')}}{{$t('colon')}}</span>
                        <small class="text-muted">{{ item.create_time }}</small>
                      </div>
                      <div>
                        <span>{{$t('u_time')}}{{$t('colon')}}</span>
                        <small class="text-muted">{{ item.update_time }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-4 rounded animated fadeInLeft">
              <div class="card mb-4 shadow">
                <div class="card-body add-project">
                  <div><i class="el-icon-plus"></i></div>
                </div>
              </div>
            </div> -->
          </div>
          <div v-if="projectList.length === 0" class="no-data">
            {{ $t("no_data") }}
          </div>
        </div>
        <div class="clearfix mt-3 mb-3">
          <div class="float-left">
            <!-- <pagination 
            :pageSize="pageSize"
            :layout="layout"
            :currentPage="currentPage"
            :total="50"
            @goToPage="handleCurrentChange"
            />-->
          </div>
        </div>
      </div>
      <div
        class="empty-wrapper"
        :class="showSearchDrawer ? 'active' : ''"
      ></div>
      <div class="search-drawer" :class="showSearchDrawer ? 'active' : ''">
        <p class="top-line clearfix">
          <span>高级搜索</span>
          <span class="float-right close-search" @click="hideSearchWrapper">关闭</span>
        </p>
      </div>
    </div>
    <project-model
      v-if="showModel"
      :modelType="modelType"
      :project="project"
      @submit-project="submitProject"
      @close-model="showModel = false"
    />
  </div>
</template>

<script>
import { projectRequest } from "../../api/api";
// import pagination from "../../components/pager";
// import { paginationMixin } from "../../utils/mixin";
import projectModel from "../../components/projectModel";
import { MessageBox, Loading } from "element-ui";

export default {
  // mixins: [paginationMixin],
  components: {
    // pagination,
    projectModel
  },
  data() {
    return {
      projectList: new Array(),
      shareProjectList: new Array(),
      showSearchDrawer: false,
      currentPage: 2,
      showModel: false,
      modelType: "",
      project: {},
      id: "",
      totalCount: 0,
      userRole: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).role
        : ""
    };
  },
  created() {
    this.getProjectData();
  },
  methods: {
    showSearchWrapper() {
      this.showSearchDrawer = true;
    },
    hideSearchWrapper() {
      this.showSearchDrawer = false;
    },
    async getProjectData() {
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let params = {
        request: "project_list",
        userid: this.$store.state.userid
      };
      try {
        let res = await projectRequest(params);
        if (res.status === 1 || res.status === 200) {
          this.projectList = res.data.length > 0 ? res.data : [];
          this.totalCount = res.total || 0
        }
        loadingInstance.close()
      } catch (error) {
        loadingInstance.close()
        this.$helper.errNotify(error.message);
      }
    },
    async getShareProject() {
      let params = {
        request: "getshareproject",
        userid: this.$store.state.userid
      };
      try {
        let res = await projectRequest(params);
        if (res.status === 1 || res.status === 200) {
          this.shareProjectList = res.data.length > 0 ? res.data : [];
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    showProjectModel(type, project) {
      this.modelType = type;
      this.showModel = true;
      if(!!project) {
        this.project = {
          id: project.id,
          name: project.name,
          operator: project.operator,
          machine: project.machine,
          description: project.description,
        }
      } else {
        this.project = {}
      }
    },
    submitProject(project) {
      if (this.modelType === "create") {
        this.createProject(project);
      } else if (this.modelType === "edit") {
        this.editProject(project);
      }
    },
    async createProject(project) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)"
      });

      let params = {
        request: "update",
        userid: this.$store.state.userid,
        id: 0,
        ...project
      };
      try {
        let res = await projectRequest(params);
        if (res.status === 200) {
          this.showModel = false;
          this.getProjectData();
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    async editProject(project) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)"
      });

      let params = {
        request: "update",
        userid: this.$store.state.userid,
        ...project
      };
      try {
        let res = await projectRequest(params);
        if (res.status === 200) {
          this.showModel = false;
          this.getProjectData();
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    deleteProject(id) {
      MessageBox.confirm(this.$t("notice.p_confirm"), this.$t("notice.tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning"
      })
        .then(() => {
          this.deleteProjectFunc(id);
        })
        .catch(() => {});
    },
    async deleteProjectFunc(id) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)"
      });

      let params = {
        request: "delete",
        projectid: id,
        userid: this.$store.state.userid
      };
      try {
        let res = await projectRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getProjectData();
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    }
  },
};
</script>

<style lang="less">
.table {
  border-bottom: 1px solid #dee2e6;
}

@media(max-width: 1400px) {
  .project-content{
    .project-item{
      flex: 0 0 50%;
      max-width: 50%;
      position: relative;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

@media(min-width: 1400px) {
  .project-content{
    .project-item{
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      position: relative;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.project-content {
  .card {
    height: 239px;
  }
  .card-line {
    margin-bottom: 0;
  }
  .group-list{
    margin-bottom: 12px;
    overflow:hidden; 
    text-overflow:ellipsis;
    white-space:nowrap;
  }
  .data-owner{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .project-desc{
    margin-top: 5px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // display:-webkit-box; 
    // -webkit-box-orient:vertical; 
    // -webkit-line-clamp:2; 
  }
  .card-body{
    padding: 10px 20px;
  }
  // .card-footer{
  //   padding: 10px 20px;
  // }
  .add-project {
    display: flex;
    justify-content: center;
    align-items: center;
    .el-icon-plus{
      font-size: 80px;
      color: #027BFE;
    }
  }
  .no-data{
    text-align: center;
  }
}
</style>
