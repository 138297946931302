<template>
  <div class="project-model">
    <div class="model-content">
      <div class="model-title" v-if="modelType === 'create'">
        {{ $t("project.c_pro") }}
      </div>
      <div class="model-title" v-if="modelType === 'edit'">
        {{ $t("project.p_edit") }}
      </div>
      <div class="model-body">
        <el-form :model="project" :rules="rules" ref="modelForm" label-width="140px">
          <el-form-item :label="$t('project.name') + '：'" prop="name">
            <el-input v-model="project.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('project.owner') + '：'" prop="operator">
            <el-input v-model="project.operator"></el-input>
          </el-form-item>
          <!-- <el-form-item :label="$t('project.equipment') + '：'" prop="machine">
            <el-input v-model="project.machine"></el-input>
          </el-form-item> -->
          <el-form-item :label="$t('project.p_des') + '：'" prop="description">
            <el-input type="textarea" v-model="project.description" :autosize="{ minRows: 2, maxRows: 20}"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="model-footer text-right">
        <button type="button" class="btn btn-grey" @click.prevent="closeModel">
          {{ $t("cancel") }}
        </button>
        <button
          type="button"
          class="btn btn-primary ml10"
          @click.prevent="submitForm('modelForm')"
        >
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelType: {
      type: String,
      default() {
        return "";
      }
    },
    project: {
      type: Object,
      default() {
        return {
          name: "",
          description: "",
          machine: "",
          operator: ""
        };
      }
    }
  },
  computed: {
    rules() {
      return {
        name: [
          { required: true, message: " ", trigger: 'change' }
        ],
        operator: [
          { required: true, message: " ", trigger: 'change' }
        ],
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("submit-project", this.project);
        } else {
          return false;
        }
      });
    },
    closeModel() {
      this.$emit("close-model");
    }
  }
};
</script>

<style lang="less">
.project-model {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
  .model-content {
    margin: 150px auto 0;
    width: 800px;
    border-radius: 5px;
    background: #fff;
  }
  .model-title {
    padding-left: 10px;
    border-bottom: 1px solid #dee2e6;
    height: 45px;
    line-height: 45px;
    font-size: 20px;
    color: #212528;
  }
  .model-body {
    padding: 10px 50px;
  }
  .model-footer {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  label{
    margin-bottom: 0;
  }
}
</style>
